
body {
  padding: 1rem;
  margin: 0;
  font-size: 1rem;
}

.wrapper {
  font-family: degular, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.625rem;
  max-width: 46.875rem;
  margin: 0 auto;
  background-color: #f6dbd6;
  border-top-left-radius: 16.25rem;
  border-top-right-radius: 16.25rem;
  border-bottom-left-radius: 3.75rem;
  border-bottom-right-radius: 3.75rem;
  text-align: center;
  padding: 3.125rem 4.5rem;
  color: #7d647d;
  box-sizing: border-box;
}

.wrapper #language-button{
  text-align: right;
  padding: 0 3.125rem;
}

.wrapper #language-button button{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
  font-size: 1.1rem;
  font-style: italic;
  font-weight: bold;
	cursor: pointer;
	outline: inherit;
}

h1 {
  font-size: 1.625rem;
}

img {
  max-width: 100%;
}

.btn {
  border-radius: 30px;
  padding: 0.75rem 3.125rem;
  transition: 0.3s;
  border: 0;
  background: #7d647d;
  color: #ffffff;
  text-decoration: none;
}

.rv {
  padding: 1.25rem 1.5625rem;
}

.image {
  background-position: center 19%;
  background-size: 80%;
  background-repeat: no-repeat;
  transform: rotate(-3deg);
}

@media (max-width: 768px) {
  .wrapper {
    padding: 1.25rem;
    font-size: 1.25rem;
  }

  img {
    max-width: 50%;
  }
  
  .logo-capitale {
    max-width: 40%;
  }
}